<template>
  <v-btn
    v-bind="$attrs"
    v-on="$listeners"
    large
    class="login-button white--text"
  >
    <slot/>
  </v-btn>
</template>

<script>
export default {
  name: 'BaseButton'
}
</script>

<style scoped>
.login-button {
  background-color: #12A2D6 !important;
  font-weight: 600;
  font-size: 13px;
  line-height: 19px;
  text-transform: none;
  letter-spacing: normal;
  border-radius: 10px;
}
</style>
