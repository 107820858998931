<template>
  <div class="auth-text-field">
    <label class="auth-text-field__label">{{ label }}</label>
    <v-text-field
      outlined
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      v-bind="$attrs"
      v-on="$listeners"
      min-height="50px"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'BaseTextField',
  inheritAttrs: false,
  props: {
    label: String
  }
}
</script>

<style scoped lang="scss">
.auth-text-field {
  &__label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 20px;
    color: #606060;
  }
}

::v-deep .v-input__slot {
  min-height: 50px !important;
  border-radius: 10px !important;
}

::v-deep fieldset {
  border: 1px solid #C4C4C4 !important;
}

::v-deep input {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #606060 !important;
}

::v-deep .v-input__append-inner {
  margin-top: 12px !important;
}

::v-deep .v-icon.v-icon {
  color: #C4C4C4;
}

::v-deep .v-input__slot {
  margin-bottom: 4px !important;
}

::v-deep .v-text-field__details {
  margin-bottom: 4px !important;
}
</style>
