<template>
  <v-dialog v-bind="options" v-model="show" max-width="700" height="600" persistent>
    <v-card class="d-flex justify-center align-center pa-5" rounded="lg">
      <div style="max-width: 448px; width: 448px;">
        <v-img v-if="type" class="modal__image mx-auto mb-5" min-height="183px" width="183px" contain :src="imageUrl"></v-img>
        <p v-if="title" class="modal__title mb-5">
          {{ title }}
        </p>
        <p v-if="message" class="modal__message mb-5">
          {{ message }}
        </p>
        <v-card-actions class="d-flex justify-space-evenly py-5">
          <base-button
            v-if="cancelText"
            class="modal__button"
            @click="cancel">
            {{ cancelText }}
          </base-button>
          <base-button
            width="100%"
            height="50px"
            class="modal__button"
            @click="agree">
            {{ acceptText }}
          </base-button>
        </v-card-actions>
      </div>
      <v-btn @click="cancel" color="#D04036" class="modal__close-icon" icon>
        <v-icon color="#FFFFFF">mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script>
import BaseButton from '@/components/auth/BaseButton'
import feedbackAjolitoImg from '@/assets/ajolitos/feedback.svg'
import noFeedbackAjolitoImg from '@/assets/ajolitos/no-feedback.svg'
import checkAjolitoImg from '@/assets/ajolitos/document.svg'

export default {
  name: 'ConfirmModal',
  components: { BaseButton },
  data () {
    return {
      show: false,
      resolve: null,
      reject: null,
      options: {},
      title: '',
      message: '',
      acceptText: 'Aceptar',
      cancelText: '',
      type: 'feedback',
      feedbackAjolitoImg,
      noFeedbackAjolitoImg,
      checkAjolitoImg
    }
  },
  computed: {
    imageUrl () {
      if (this.type === 'check') {
        return checkAjolitoImg
      } else if (this.type === 'error') {
        return noFeedbackAjolitoImg
      } else if (this.type === 'feedback') {
        return feedbackAjolitoImg
      } else {
        return ''
      }
    }
  },
  methods: {
    open ({ title, message, acceptText, cancelText, type, options }) {
      this.show = true
      this.title = title
      this.message = message
      this.acceptText = acceptText || 'ok'
      this.cancelText = cancelText
      this.type = type
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree () {
      this.resolve(true)
      this.show = false
    },
    cancel () {
      this.resolve(false)
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
.modal {

  &__title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #12A2D6;
    margin-bottom: 20px;
  }

  &__message {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    color: #606060;
  }

  &__close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #D04036;
  }
}

::v-deep .modal__close-icon .v-icon::before {
  font-weight: 600;
}

::v-deep .v-dialog {
  border-radius: 15px;
}
</style>
