<template>
    <v-dialog
      persistent
      width="300"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-card class="py-2">
        <v-card-text>
          Espere un momento...
          <v-progress-linear
            indeterminate
            color="#1976d2"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  name: 'LoadingModal',
  inheritAttrs: false
}
</script>

<style scoped>

</style>
